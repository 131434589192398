import type { LocalDeliveryAddress } from "~/commerce-sap/.server/sessions.server";

import { absoluteUrl } from "@commerce/lib/url";

import type { OrderHistory } from "~/commerce-sap/.server/api/generated/__generated_apis";
import type { Config } from "~/config/default";
import type { Locale, Site } from "~/contexts";

import { getConfig, getSites, resolveSiteFromUrl } from "./site-utils";

const prefix = "Invariant failed";
export function invariant(
  condition: unknown,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string),
  cb: () => any = noop,
): asserts condition {
  if (condition) {
    return;
  }
  // Condition not passed

  // When not in production we allow the message to pass through
  // *This block will be removed in production builds*

  const provided: string | undefined =
    typeof message === "function" ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const value: string = provided ? `${prefix}: ${provided}` : prefix;
  cb?.();
  throw new Error(value);
}

/**
 * Call requestIdleCallback in supported browsers.
 *
 * https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 * http://caniuse.com/#feat=requestidlecallback
 */
export const requestIdleCallback = (fn: IdleRequestCallback) => {
  if ("requestIdleCallback" in window) {
    return window.requestIdleCallback(fn);
  } else {
    return setTimeout(fn, 1);
  }
};

export const watchOnlineStatus = (
  callback: {
    (isOnline: boolean): any;
  },
  win = window,
) => {
  const off = () => callback(false);
  const on = () => callback(true);
  win.addEventListener("offline", off);
  win.addEventListener("online", on);
  const unsubscribe = () => {
    win.removeEventListener("offline", off);
    win.removeEventListener("online", on);
  };
  return unsubscribe;
};

//Performs a shallow comparison on two objects
export const shallowEquals = (
  obj1: { [x: string]: any },
  obj2: { [x: string]: any },
) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

/**
 * No operation function. You can use this
 * empty function when you wish to pass
 * around a function that will do nothing.
 * Usually used as default for event handlers.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Check the current execution environment
 * is client side or server side
 * @returns Boolean
 */
export const isServer = typeof window === "undefined";

/**
 * retrieves an item from session storage
 * @param {string} key
 * @returns JSON | undefined
 */
export const getSessionJSONItem = (key: string) => {
  if (isServer) {
    return undefined;
  }
  const item = window.sessionStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  } else {
    return undefined;
  }
};
/**
 * sets an item in session storage
 * @param {string} key
 * @param {string} value
 */
export const setSessionJSONItem = (key: string, value: unknown) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * clears an item in session storage
 * @param {string} key
 */
export const clearSessionJSONItem = (key: string) => {
  window.sessionStorage.removeItem(key);
};

/**
 * bolds a substring of a string by adding <b> tags
 * @param {string} str
 * @param {string} substr
 * @returns stringified HTML Node
 */
export const boldString = (str: string, substr: string) => {
  return str.replace(RegExp(substr, "g"), `<b>${substr}</b>`);
};

/**
 * Capitalizes the words in a string
 * @param {string} text
 * @returns capitalized text
 */
export const capitalize = (text: string) => {
  return text
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

/**
 * This function return the identifiers (site and locale) from the given url
 * The site will always go before locale if both of them are presented in the pathname
 * @param path {string}
 * @returns {{siteRef: string, localeRef: string}} - site and locale reference (it could either be id or alias)
 */
export const getParamsFromPath = (path: string) => {
  const { pathname, search } = new URL(absoluteUrl(path));

  const config = getConfig();
  const { pathMatcher, searchMatcherForSite, searchMatcherForLocale } =
    getConfigMatcher(config);
  const pathMatch = pathname.match(pathMatcher);
  const searchMatchForSite = search.match(searchMatcherForSite);
  const searchMatchForLocale = search.match(searchMatcherForLocale);

  // the value can only either in the path or search query param, there will be no overridden
  const siteRef = pathMatch?.groups?.site || searchMatchForSite?.groups?.site;

  const localeRef =
    pathMatch?.groups?.locale || searchMatchForLocale?.groups?.locale;
  return { siteRef, localeRef };
};

/**
 * This function returns the url config from the current configuration
 * @return {object} - url config
 */
export const getUrlConfig = () => {
  const { app } = getConfig();
  if (!app.url) {
    throw new Error(
      "Cannot find `url` key. Please check your configuration file.",
    );
  }
  return app.url;
};

/**
 * Given your application's configuration this function returns a set of regular expressions used to match the site
 * and locale references from an url.
 * @param config
 * @return {{searchMatcherForSite: RegExp, searchMatcherForLocale: RegExp, pathMatcher: RegExp}}
 */
export const getConfigMatcher = (config: Config) => {
  if (!config) {
    throw new Error("Config is not defined.");
  }

  const allSites = getSites();
  const siteIds: string[] = [];
  const siteAliases: string[] = [];
  const localesIds: string[] = [];
  const localeAliases: string[] = [];
  allSites.forEach(site => {
    if (site.alias) siteAliases.push(site.alias);
    siteIds.push(site.id);
    const { l10n } = site;
    l10n.supportedLocales.forEach(locale => {
      localesIds.push(locale.id);
      if (locale.alias) localeAliases.push(locale.alias);
    });
  });
  const sites = [...siteIds, ...siteAliases].filter(Boolean);
  const locales = [...localesIds, ...localeAliases].filter(Boolean);

  // prettier-ignore
  // eslint-disable-next-line
  const searchPatternForSite = `site=(?<site>${sites.join('|')})`
  // prettier-ignore
  // eslint-disable-next-line
  const pathPattern = `(?:\/(?<site>${sites.join('|')}))?(?:\/(?<locale>${locales.join("|")}))?(?!\\w)`
  // prettier-ignore
  // eslint-disable-next-line
  const searchPatternForLocale = `locale=(?<locale>${locales.join('|')})`
  const pathMatcher = new RegExp(pathPattern);
  const searchMatcherForSite = new RegExp(searchPatternForSite);
  const searchMatcherForLocale = new RegExp(searchPatternForLocale);
  return {
    pathMatcher,
    searchMatcherForSite,
    searchMatcherForLocale,
  };
};

/**
 * Given a site and a locale reference, return the locale object
 * @param site - site to look for the locale
 * @param localeRef - the locale ref to look for in site supported locales
 */
export const getLocaleByReference = (site: Site, localeRef: string) => {
  if (!site) {
    throw new Error(
      "Site is not defined. It is required to look for locale object",
    );
  }
  return site.l10n.supportedLocales.find(
    locale => locale.id === localeRef || locale.alias === localeRef,
  );
};

/**
 * Determine the locale object from an url
 * If the localeRef is not found from the url, set it to default locale of the current site
 * and use it to find the locale object
 *
 * @param url
 */
export const resolveLocaleFromUrl = (url: string): Locale => {
  if (!url) {
    throw new Error("URL is required to look for the locale object");
  }
  let { localeRef } = getParamsFromPath(url);
  const site = resolveSiteFromUrl(url);
  const { supportedLocales } = site.l10n;
  // if no localeRef is found, use the default value of the current site
  if (!localeRef) {
    localeRef = site.l10n.defaultLocale;
  }
  let locale = supportedLocales.find(
    locale => locale.alias === localeRef || locale.id === localeRef,
  );
  if (locale) {
    return locale;
  }
  // if locale is not defined, use default locale as fallback value
  const defaultLocale = site.l10n.defaultLocale;
  locale = supportedLocales.find(
    locale => locale.alias === defaultLocale || locale.id === defaultLocale,
  );

  if (!locale) {
    throw new Error("Cannot find the locale default locale");
  }
  return locale;
};

/**
 * Validates if a trimmed HTML string contains a <table> element with at least one <tr> (table row),
 * ensuring basic table structure is present before using it with dangerouslySetInnerHTML.
 *
 * @param htmlString
 */
export const isValidTableString = (htmlString: string): boolean => {
  return (
    !!htmlString?.trim() &&
    /<table[^>]*>.*<\/table>/i.test(htmlString.trim()) &&
    /<tr[^>]*>.*<\/tr>/i.test(htmlString.trim())
  );
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};

export const formatDateString = (date: string) => {
  const year = date.split("-")[0];
  const month = monthNames[Number(date.split("-")[1]) - 1];
  const day = date.split("T")[0].split("-")[2];
  return `${day} ${month}, ${year}`;
};

export const formatSearchResult = (result: string) => {
  const addressArr = result.split(",");
  const line1 = addressArr[0];
  const line2 = addressArr.length === 2 ? "" : addressArr[1].trim();
  const regionArr = addressArr[addressArr.length - 1].split(" ");
  const postalCode = regionArr[regionArr.length - 1];
  const state = regionArr[regionArr.length - 2];
  const town = regionArr
    .slice(0, regionArr.length - 2)
    .join(" ")
    .trim();
  return { line1, line2, postalCode, state, town };
};

export const valueFromArray = (
  sourceArray: [string, FormDataEntryValue][],
  index: number,
) => (sourceArray && sourceArray[index] && sourceArray[index][1]) || "";
export const reverseFormatSearchResult = (addressObj: {
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
  town: string;
}) => {
  const { line1, line2, postalCode, state, town } = addressObj;
  const region = `${town} ${state} ${postalCode}`;
  return `${line1}, ${line2}, ${region}`;
};

export const constructLocationQuery = (address: LocalDeliveryAddress) => {
  return `${address.suburb ?? ""} ${address.state ?? ""} ${
    address.postcode ?? ""
  }`.trim();
};

export const sortOrdersById = (
  orders: OrderHistory[],
  orderId?: string | null,
): OrderHistory[] => {
  if (!orderId) return orders;
  if (!orders.some(order => order.code === orderId)) return orders;
  return orders.sort((a, b) => {
    if (a.code === orderId) return -1;
    if (b.code === orderId) return 1;
    return 0;
  });
};
